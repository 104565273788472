// Required dependencies
import 'jquery';
import 'materialize-css';
import 'material-icons/iconfont/material-icons.css';

$(document).ready(function(){
    $('.sidenav').sidenav();
});

import './theme/style.scss';
